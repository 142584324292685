<template>
  <div class="elevation-6 profile" style="width: 100%">
    <div style="width: 100%">
      <v-img
        :src="selectedFile ? selectedImage : image ? image : placeholderImage"
        alt="Avatar"
        width="100%"
        :aspect-ratio="ratio"
      />
    </div>
    <v-btn
      color="primary"
      class="edit_button"
      fab
      dark
      x-small
      :loading="isSelectingPhoto"
      @click="onEditButtonClick"
      v-if="editable"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
    },
    onImageSelected: {
      type: Function,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    ratio: Number,
  },
  data() {
    return {
      selectedFile: null,
      placeholderImage: require("@/assets/background0.png"),
      isSelectingPhoto: false,
      selectedImage: null,
    };
  },
  methods: {
    onEditButtonClick() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
        this.onImageSelected(this.selectedFile);
      }
    },
    clearSelected() {
      this.selectedImage = null;
      this.selectedFile = null;
    },
  },
};
</script>
<style scoped>
.profile {
  overflow: visible;
  position: relative;
}
.edit_button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
