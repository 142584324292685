<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card class="d-flex flex-column" style="max-height: 80vh">
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit Ads
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div style="flex-grow: 1; overflow: auto">
        <v-card-text style="font-family:'Poppins-Regular';">
          Enter the details for the new ads.
        </v-card-text>
        <v-card-text
          class="d-flex justify-center flex-column app-medium-font dark-font-color"
        >
          Select Type
          <div class="d-flex justify-center">
            <v-select
              :items="typeOptions"
              v-model="profile.type"
              dense
              outlined
              hide-details
              @change="onChange"
            />
          </div>
        </v-card-text>
        <v-card-text v-if="profile.type == 'video'" class="pb-0">
          <v-btn @click="onSelectVideo" class="mb-5">Select video</v-btn>
          <vue-plyr
            ref="plyr"
            :options="{
              autoplay: false,
              muted: true,
            }"
            v-if="selectedVideo"
          >
            <video controls crossorigin playsinline>
              <source :src="selectedVideo" type="video/mp4" />
            </video>
          </vue-plyr>
        </v-card-text>
        <v-card-text
          class="d-flex justify-center flex-column app-medium-font dark-font-color"
          v-if="profile.type == 'image'"
        >
          Select Image
          <div class="d-flex justify-center">
            <editable-ads-image
              :image="profile.file"
              :onImageSelected="onImageSelected"
              :ratio="1 / 1"
            />
          </div>
        </v-card-text>
        <v-card-text
          class="d-flex justify-center flex-column app-medium-font dark-font-color"
          v-if="profile.type == 'image'"
        >
          Select Banner
          <div class="d-flex justify-center">
            <editable-ads-image
              :image="profile.banner"
              :onImageSelected="onBannerImageSelected"
              :ratio="728 / 90"
            />
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <v-select
            label="Business"
            solo
            flat
            outlined
            class="mt-2"
            v-model="profile.business"
            style="font-family:'Poppins-Regular';"
            :rules="[rules.required]"
            hide-details
            :items="business"
            item-text="name"
            item-value="_id"
          />
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <v-text-field
            label="Url"
            solo
            flat
            outlined
            class="mt-2"
            v-model="profile.url"
            style="font-family:'Poppins-Regular';"
            :rules="[rules.required]"
          />
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <v-textarea
            label="Text"
            solo
            flat
            outlined
            class="mt-2"
            v-model="profile.text"
            style="font-family:'Poppins-Regular';"
            :rules="[rules.required]"
            rows="3"
          />
        </v-card-text>
        <v-card-text v-if="message">
          <v-alert dense type="error" v-text="message" v-if="message" />
        </v-card-text>
      </div>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        :value="file"
        accept="video/*"
        @change="onFileChanged"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAdsImage from "./EditableAdsImage.vue";
export default {
  components: { EditableAdsImage },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    ads: Object,
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      name: "",
      message: null,
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      selectedImage: null,
      selectedBannerImage: null,
      selectedVideo: null,
      selectedFile: null,
      profile: { type: "image" },
      typeOptions: ["image", "video"],
      errorMessage: null,
      snackbar: false,
      file: null,
    };
  },
  methods: {
    ...mapActions({
      editAds: "ads/editAds",
      fetchAllBusiness: "business/fetchAllBusiness",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (
        !this.profile.file &&
        !this.selectedImage &&
        this.profile.type == "image"
      ) {
        this.message = "Please select the ads image.";
        return;
      }
      if (
        !this.profile.banner &&
        !this.selectedBannerImage &&
        this.profile.type == "image"
      ) {
        this.message = "Please select the banner image.";
        return;
      }
      if (!this.selectedFile && this.profile.type == "video") {
        this.message = "Please select the video.";
        return;
      }

      if (!this.profile.url) {
        this.message = "Please add the url.";
        return;
      }
      if (!this.profile.business) {
        this.message = "Please select a business.";
        return;
      }
      // if (!this.profile.text) {
      //   this.message = "Please add text.";
      //   return;
      // }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("file", this.selectedImage);
      }
      if (this.selectedBannerImage) {
        formData.append("banner", this.selectedBannerImage);
      }
      if (this.profile.text) {
        formData.append("text", this.profile.text);
      }
      if (this.profile.business) {
        formData.append("business", this.profile.business);
      }
      if (this.profile.url) {
        formData.append("url", this.profile.url);
      }
      if (this.profile.text) {
        formData.append("text", this.profile.text);
      }
      if (this.selectedFile) {
        formData.append("file", this.selectedFile);
      }
      formData.append("_id", this.profile._id);
      this.loading = true;
      this.editAds(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    },
    onBannerImageSelected(image) {
      this.selectedBannerImage = image;
      this.message = null;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onSelectVideo() {
      setTimeout(() => {
        window.addEventListener("focus", () => {}, { once: true });
        this.$refs.uploader.click();
      }, 300);
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        if (this.type == "image") {
          this.selectedImage = URL.createObjectURL(this.selectedFile);
        } else {
          this.selectedVideo = URL.createObjectURL(this.selectedFile);
        }
      }
    },
    onRemoveAttachment() {
      if (this.profile.type == "video") {
        setTimeout(() => {
          this.selectedVideo = null;
          this.selectedBannerImage = null;
          this.selectedImage = null;
        }, 200);
      } else {
        this.selectedVideo = null;
        this.selectedBannerImage = null;
        this.selectedImage = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      business: "business/getAllBusiness",
    }),
  },
  mounted() {
    this.profile = { ...this.ads };
    this.loading = true;
    this.fetchAllBusiness()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped></style>
