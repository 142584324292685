<template>
  <v-card>
    <div class="ads-card-content pa-5">
      <v-img
        :src="ads.file"
        alt="ads logo"
        width="240"
        height="150"
        max-height="150"
        max-width="240"
        class="rounded-lg"
        v-if="ads.type == 'image'"
      />
      <div v-else-if="ads.type == 'video' && ads.file">
        <vue-plyr
          ref="plyr"
          :options="{
            autoplay: true,
            muted: true,
            autopause: false,
          }"
        >
          <video
            controls
            crossorigin
            playsinline
            id="videoElement"
            :settings="['loop']"
            loop
          >
            <source :src="ads.file" type="video/mp4" />
          </video>
        </vue-plyr>
      </div>
      <div class="ads-description-section">
        <span class="ads-url px-3">{{ ads.url }}</span>
        <span class="ads-description mb-1 px-3 mt-3 text-block">
          {{ ads.text }}
        </span>
        <span class="px-3">{{ ads.business_name }}</span>
        <v-btn
          class="mr-2"
          style="position: absolute; right:45px; top: 15px;"
          icon
          @click="onEditAds"
          ><v-icon color="#472583">mdi-pencil</v-icon></v-btn
        >
        <v-btn
          class="mr-2"
          style="position: absolute; right:5px; top: 15px;"
          icon
          @click="onDeleteAds"
          ><v-icon color="#472583">mdi-delete</v-icon></v-btn
        >
      </div>
    </div>
    <admin-edit-ads-dialog
      :onClose="onCloseDialog"
      :ads="ads"
      :dialog="editDialog"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDelete"
    />
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import AdminEditAdsDialog from "../../views/admin/ads/AdminEditAdsDialog.vue";
import ConfirmDeleteDialog from "../common/ConfirmDeleteDialog.vue";
export default {
  components: { AdminEditAdsDialog, ConfirmDeleteDialog },
  props: {
    ads: Object,
  },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
    };
  },
  methods: {
    ...mapActions("ads", {
      editAds: "editAds",
      deleteAds: "deleteAds",
    }),
    onEditAds() {
      this.editDialog = true;
    },
    onDeleteAds() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.editDialog = false;
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.deleteAds({ _id: this.ads._id }).catch((error) => {
        console.log(error.response.data.message);
      });
    },
  },
};
</script>
<style>
.ads-title {
  color: #472583;
  font-size: 18px;
  min-height: 40px;
  font-weight: 500;
}
.ads-url {
  color: #472583;
  font-size: 14px;
  font-weight: 500;
}
.ads-description-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ads-description {
  color: #472583;
  font-size: 14px;
  min-height: 50px;
}

.ads-card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 500px;
}

div >>> .plyr video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
div >>> .plyr__video-wrapper {
  padding-top: 56.2%;
}
</style>
